import React from "react";
import { Route } from "react-router-dom";

export default ({ component: C, props: cProps, extraProps, ...rest }) =>
    <Route
        {...rest}
        render={props =>
            <C {...props} {...cProps} {...extraProps}/>
        }
    />;
