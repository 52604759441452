import React, {Component} from "react";
import {Input} from "reactstrap";
import "../../styles/search.css";
import {getStorySuggestions} from "../../services/search";
import {NavLink} from "react-router-dom";

export default class SearchPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchKeyword: '',
            suggestions: []
        }
    }

    getSearchSuggestions = async (keyword) => {
        this.setState({searchKeyword: keyword});
        if(keyword.length >= 3){
            const suggestions = await getStorySuggestions(keyword);
            this.setState({suggestions});
        } else{
            this.setState({suggestions: []});
        }
    }

    handleKeyPress = (target) => {
        const {searchKeyword} = this.state;
        if(target.charCode === 13 && searchKeyword.trim().length > 0){
            this.props.history.push(`/search/${encodeURIComponent(searchKeyword)}`);
        }
    }


    render() {
        const {searchKeyword, suggestions} = this.state;

        return (
            <div className="d-flex flex-grow-1 flex-column w-100 align-items-center">
                <Input value={searchKeyword} onChange={(event) => this.getSearchSuggestions(event.target.value)}
                       className="w-50 search-main border-right-0 border-left-0 border-top-0 border-bottom rounded-0
                                  h-100p mt-20-vh text-center main-search" placeholder="Type here to search"
                       onKeyPress={this.handleKeyPress}/>
                {
                    suggestions.length > 0 &&
                    <div className="w-50 d-flex flex-column search-result-div mb-3">
                        <div className="px-3 d-flex flex-column overflow-auto" style={{maxHeight: '40vh'}}>
                            {suggestions.map((suggestion, index) => {
                                return(
                                    <NavLink to={`/story/${suggestion._id}?view=story`}>
                                        <div className={`d-flex justify-content-between align-items-center border-secondary 
                                        py-3 ${index!==suggestions.length-1 ? 'border-bottom' : ''}`}>
                                            <div>
                                                <i className="fa fa-search mr-4"/>
                                                <span>{suggestion.title}</span>
                                            </div>
                                            <div>
                                                <NavLink to={`/story/${suggestion._id}?view=graph`}>
                                                    <div className="btn btn-secondary mr-3">
                                                        View Graph
                                                    </div>
                                                </NavLink>
                                                <NavLink to={`/story/${suggestion._id}?view=story`}>
                                                    <div className="btn btn-secondary">
                                                        View Story
                                                    </div>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </NavLink>
                                );
                            })}
                        </div>
                        <div className="bg-secondary d-flex justify-content-center py-3 font-medium">
                            <div onClick={this.props.randomStory} className="btn btn-link p-0 text-info mr-4">Random Search</div>
                            <NavLink to="/advance-search" className="text-info">Advance Search</NavLink>
                        </div>
                    </div>
                }
                {
                    suggestions.length === 0 &&
                        <div className="d-flex w-50 justify-content-center font-medium mt-3">
                            <div onClick={this.props.randomStory} className="btn btn-link p-0 text-info mr-4">Random Search</div>
                            <NavLink to="/advance-search" className="text-info">Advance Search</NavLink>
                        </div>
                }
            </div>
        )
    }
}
