import {randomNumber} from "./common";

export const getMockSuggestions = (keyword) => {
    return [1, 2, 3, 4, 5].map(val => {
        return {title: `${keyword}${val}`, id: `random${val}`}
    })
}

export const getMockSearchResults = (keyword) => {
    const arrLength = randomNumber(0, 10);
    return new Array(parseInt(arrLength)).fill(1).map((val, index) => {
        return {
            title: `${keyword}${index}`, _id: `random${index}`,
            description: `This is some random description for result ${keyword}${index}. Just adding some text to look ui filled.`.repeat(index + 1)
        }
    });
}

export const getMockStory = (id) => {
    return {
        id,
        title: 'Polymerase Chain Reaction',
        description: 'Polymerase Chain Reaction (pcr) is a molecular biology technique that allows one to easily create an enormous amount of copies of a particular DNA sequence.',
        parents: [{title: 'DNA Polymerase', _id: 'abcdef'}],
        associates: [{title: 'DNA Structure', _id: 'abdfrg'}],
        document: 'http://rise.wiki/Public/StoryView/PrintStoryView?hdnNodeIDVal=95,Polymerase%20Chain%20Reaction',
        graph: {
            "nodes": [{
                id: 1,
                label: 'Node 1',
                shape: 'circle'
            }, {
                id: 2,
                label: 'Node 2',
                shape: 'circle'
            }, {
                id: 3,
                label: 'Node 3',
                shape: 'circle'
            }, {
                id: 4,
                label: 'Node 4',
                shape: 'circle'
            }, {
                id: 5,
                label: 'Node 5',
                shape: 'circle'
            }],
            "edges": [{from: 1,to: 2, arrows:'to'},
                {from: 1,to: 3, arrows:'to'},
                {from: 2,to: 4, arrows:'to'},
                {from: 2,to: 5, arrows: ''}
            ]
        }
    }
}

export const getMockRandomStoryId = () => {
    return Math.random().toString(36).substring(7);
}

export const getMockAdvanceSearchResults = () => {
    const keyword = Math.random().toString(36).substring(7);
    return getMockSearchResults(keyword);
}

export const getMockPeople = () => {
    return [{name: "Sahil", ethnicity: "Asian", gender: "Male"}, {name: "Sachin", ethnicity: "Asian", gender: "Male"}];
}
