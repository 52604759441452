import React, {Fragment} from "react";
import {Button} from "reactstrap";

export default ({isLoading, text, loadingText, disabled = false, ...props}) => {
    return (
        <Fragment>
            <Button
                dangerouslySetInnerHTML={{__html: !isLoading ? text : loadingText}}
                disabled={disabled || isLoading}
                {...props}/>
        </Fragment>
    )
}
