import React from "react";
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import * as _ from "underscore";
import {storyExcludedFields, storyView} from "../../utils/mapping";

const styles = StyleSheet.create({
    page: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column'
    },
    section: {
        marginLeft: 20,
        marginRight: 20,
        paddingBottom: 20,
        paddingTop: 20,
        borderBottom: 1,
        borderBottomColor: 'black'
    },
    title: {
        alignSelf: 'center',
        marginBottom: 20,
        marginTop: 20
    },
    headerFont: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    descriptionFont: {
        fontSize: 14,
        fontWeight: 'normal'
    }
});

export const PdfView = ({story}) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.title}>
                    <Text style={styles.headerFont}>{story.title}</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.headerFont}>Title</Text>
                    <Text style={styles.descriptionFont}>{story.title}</Text>
                </View>
                {
                    story.description &&
                    <View style={styles.section}>
                        <Text style={styles.headerFont}>Description</Text>
                        <Text style={styles.descriptionFont}>{story.description}</Text>
                    </View>
                }
                {
                    (story.parents.length>0 || story.children.length>0 || story.associates.length>0) &&
                    <View style={styles.section}>
                        <Text style={styles.headerFont}>Relationships</Text>
                        {
                            ["children", "parents", "associates"].filter(key=>!_.isEmpty(story[key])).map((key, index) =>
                                <Text key={index} style={styles.descriptionFont}>
                                    {storyView[key] || key}: {story[key].map(s=>s.title).join(', ')}
                                </Text>)
                        }
                    </View>
                }
                {
                    story.people && story.people.length > 0 &&
                    <View style={styles.section}>
                        <Text style={styles.headerFont}>People Involved</Text>
                        <Text style={styles.descriptionFont}>{story.people.map(p => p.name).join(', ')}</Text>
                    </View>
                }
                {
                    Object.keys(story).filter(key => !storyExcludedFields.includes(key) && !_.isEmpty(story[key])).map((key, index) => {
                        return (
                            <View style={styles.section} key={index}>
                                <Text style={styles.headerFont}>{storyView[key] || key}</Text>
                                {
                                    Array.isArray(story[key]) &&
                                    <Text style={styles.descriptionFont}>{story[key].join(', ')}</Text>
                                }
                                {
                                    !Array.isArray(story[key]) &&
                                    <Text style={styles.descriptionFont}>{story[key]}</Text>
                                }
                            </View>
                        )
                    })
                }
            </Page>
        </Document>
    );
}
