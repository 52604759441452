import React, {useState, useEffect} from "react";
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader} from "reactstrap";
import LoaderButton from "../../toolkit/LoaderButton";

export const AddEdgePopup = ({show, onClose, title, callback, data, btnText, btnLoadingText}) => {

    const [isAdding, setIsAdding] = useState(false);
    const [edgeType, setEdgeType] = useState(data && data.arrows==='to' ? 'hierarchy' : 'associated');
    if(!data || !callback){
        return null;
    }

    const addEdge = () => {
        setIsAdding(true);
        if (typeof data.to === "object") data.to = data.to.id;
        if (typeof data.from === "object") data.from = data.from.id;
        if(edgeType==='associated'){
            data.arrows = '';
        }
        callback(data);
        setIsAdding(false);
        onClose();
    }

    return (
        <Modal isOpen={show}>
            <ModalHeader>
                {title}
            </ModalHeader>
            <ModalBody>
                <FormGroup check>
                    <Label check>
                        <Input type="radio" name="relationship" onClick={()=>setEdgeType('hierarchy')}
                            checked={edgeType==='hierarchy'} />{' '}
                        Parent / Child
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input type="radio" name="relationship" onClick={()=>setEdgeType('associated')}
                        checked={edgeType==='associated'}/>{' '}
                        Associated
                    </Label>
                </FormGroup>
                <div className="d-flex justify-content-center">
                    <LoaderButton
                        type="button"
                        onClick={addEdge}
                        className="bg-warning semi-bold text-secondary m-3 px-4 border-custom"
                        isLoading={isAdding}
                        text={btnText}
                        loadingText={btnLoadingText}
                    />
                    <Button className="btn-danger semi-bold m-3 px-4"
                            onClick={onClose}>Cancel</Button>
                </div>
            </ModalBody>
        </Modal>);
}
