import React, {Component} from "react";
import {getSearchResults} from "../../services/search";
import {SearchResultBlock} from "./SearchResultBlock";

export default class SearchResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyword: decodeURIComponent(props.match.params.keyword),
            results: null
        }
    }

    componentDidMount() {
        this.populateResults();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let keyword = decodeURIComponent(this.props.match.params.keyword);
        if(keyword !== prevState.keyword){
            this.setState({keyword}, this.populateResults);
        }
    }

    populateResults = async () => {
        const {keyword} = this.state;
        const results = await getSearchResults(keyword);
        this.setState({results});
    }

    render() {
        const {keyword, results} = this.state;
        return(
            <div className="d-flex flex-column flex-grow-1 px-5 mx-5 mt-4">
                <h4 className="mb-0">{`Search Results for ${keyword}`}</h4>
                {
                    results && results.length === 0 &&
                        <span className="small text-primary mb-4">No results found</span>
                }
                {
                    results && results.length > 0 &&
                        <span className="small text-warning mb-4">{`About ${results.length} results match your search`}</span>
                }
                {
                    results && results.length > 0 &&
                    <SearchResultBlock results={results}/>
                }
            </div>
        )
    }
}
