import React from 'react';
import ReactDOM from 'react-dom';
import "./styles/main.scss";
import App from './App';
import { HashRouter as Router } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Amplify from "aws-amplify";
import {cognito} from "./utils/config";
import "./styles/index.css";

ReactDOM.render(
    <Router>
        <App />
    </Router>,
    document.getElementById('root')
);

Amplify.configure({
        Auth: {
                mandatorySignIn: true,
                region: cognito.REGION,
                userPoolId: cognito.USER_POOL_ID,
                userPoolWebClientId: cognito.APP_CLIENT_ID,
                storage: window.localStorage
        }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
