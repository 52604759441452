import React, {useState} from "react";
import {Dropdown, DropdownMenu, DropdownItem, DropdownToggle} from "reactstrap";

export const DropDown = ({onChange, selected, values, placeHolder}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const dropDownChange = (event) => {onChange(event)};
    return (
        <Dropdown isOpen={dropdownOpen} className='w-100 mb-3' toggle={toggle} style={{height: 38}}>
            <DropdownToggle className='w-100 d-flex justify-content-between align-items-center h-100 px-3' caret>
                {selected ? selected : placeHolder || 'Select...'}
            </DropdownToggle>
            <DropdownMenu className='w-100' style={{maxHeight: 200, overflow: 'auto'}}>
                {values.map((org, index) => {
                    return (
                        <DropdownItem tag='option' key={index} onClick={dropDownChange}
                                      active={org===selected} value={org}>{org}</DropdownItem>)})
                }
            </DropdownMenu>
        </Dropdown>);
};
