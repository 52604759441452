import {callAPI} from "./wrapper";
import {BASE_URL, MOCK} from "../utils/constants";
import {sleep} from "../utils/common";

export async function sendContactDetails(body) {
    if(MOCK){
        await sleep(1000);
        return {status: 'OK'};
    }
    return await callAPI(BASE_URL + `contact`, 'POST', body);
}

