const ethnicity = [
    'White',
    'Black or African American',
    'American Indian or Alaska Native',
    'Asian',
    'Native Hawaiian or Other Pacific Islander'
];

export const storyView = {
    people: 'People Involved',
    organization_involved: 'Organizations',
    funding_services: 'Funding Services',
    locations: 'Locations',
    references: 'References',
    timeframe: 'Timeframe',
    children: 'Child',
    parents: 'Parent',
    associates: 'Associate'
};

export const storyExcludedFields = ['title', 'parents', 'created', 'updated', '_id', 'id', 'created_by', 'modified_by',
    'children', 'associates', 'description', 'graph', 'people'];

export const advanceSearchFields = {
    name: {
        type: "text", mandatory: false, value: "", title: "People", space: 1,
        values: []
    },
    gender: {
        type: "dropDown", values: ["Male", "Female", "Prefer not to disclose"], mandatory: false, placeHolder: "", value: "", title: "Gender",
        space: 0.5
    },
    ethnicity: {
        type: "dropDown", mandatory: false, value: "", title: "Ethnicity",
        values: ethnicity, space: 1
    },
    organization_involved: {
        type: "text", mandatory: false, value: "", title: "Organizations",
        space: 1
    },
    funding_services: {
        type: "text", mandatory: false,
        title: "Funding Services", space: 1, value: ""
    },
    timeframe: {
        type: "text", mandatory: false, title: "Timeframe", value: "", space: 1
    },
    locations: {
        type: "text", mandatory: false, value: "", title: "Locations", space: 1
    },
    parents: {
        type: "text", mandatory: false, title: "Parent", value: "", space: 1
    },
    children: {
        type: "text", mandatory: false, title: "Children", value: "", space: 1
    },
    associates: {
        type: "text", mandatory: false, title: "Associated", value: "", space: 1
    }
}

export const addStoryFields = {
    title: {type: "text", value: "", mandatory: true, space: 2, title: "Title"},
    description: {type: "textarea", value: "", mandatory: false, space: 2, title: "Description"},
    people: {type: "multiDropDown", mandatory: false, value: "", title: "People", space: 2, values: []},
    parents:{type: "autoComplete", mandatory: false, value: "", title: "Parent Stories", space: 2},
    children:{type: "autoComplete", mandatory: false, value: "", title: "Child Stories", space: 2},
    associates:{type: "autoComplete", mandatory: false, value: "", title: "Associate Stories", space: 2},
    organization_involved: {type: "textarea", mandatory: false, value: "", title: "Organizations Involved", space: 2},
    funding_services: {type: "textarea", mandatory: false, value: "", title: "Funding Services", space: 2},
    timeframe: {type: "textarea", mandatory: false, title: "Time Frame", value: "", space: 2},
    locations: {type: "textarea", mandatory: false, value: "", title: "Locations", space: 2},
    references: {type: "textarea", mandatory: false, value: "", title: "References", space: 2}
}

export const addPeopleFields = {
    name: {type: "text", mandatory: true, value: "", space: 1, title: "Name"},
    ethnicity: {type: "dropDown", mandatory: true, value: "", space: 1, title: "Ethnicity", values: ethnicity},
    gender: {type: "dropDown", mandatory: false, value: "", title: "Gender",
        values: ["Male", "Female", "Prefer not to disclose"], space: 1}
}
