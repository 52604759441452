import React, {Component} from "react";
import {Form, Input, Label} from 'reactstrap';
import {addStoryFields} from "../../utils/mapping";
import {fetchStory, updateStory} from "../../services/story";
import {DropDown} from "../../toolkit/DropDown";
import Select from "react-dropdown-select";
import {NavLink} from "react-router-dom";
import LoaderButton from "../../toolkit/LoaderButton";
import {AddPeoplePopup} from "../../toolkit/AddPeoplePopup";
import {fetchPeople} from "../../services/people";
import {getStorySuggestions} from "../../services/search";
import * as _ from 'underscore';

export default class EditStory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: JSON.parse(JSON.stringify(addStoryFields)),
            id: props.match.params.id,
            addPeoplePopup: false,
            error: '',
            isUpdating: false,
            people: [],
            story: {}
        }
    }

    componentDidMount() {
        this.getPeople();
        this.getStory(this.state.id);
    }

    getPeople = async () => {
        let people = await fetchPeople();
        this.setState({people});
    }

    validateForm = () => {
        const {form} = this.state;
        let valid = true;
        Object.keys(form).forEach(field => {
            valid = valid && (form[field]['mandatory'] ? form[field]['value'] : true);
        });
        return valid;
    };

    updateValue = (event, field, isValue = false) => {
        const {form} = this.state;
        if (isValue) {
            form[field]['value'] = event;
        } else {
            form[field]['value'] = event.target.value;
        }
        this.setState({form: form});
    };

    updateStory = async (event) => {
        event.preventDefault();
        const {story, form, id} = this.state;
        const body = {};
        Object.keys(form).forEach(field => {
            if (form[field]['value'] && !_.isEqual(form[field]['value'], story[field])) {
                body[field] = form[field]['value'];
            }
        });
        console.log(body);
        this.setState({isUpdating: true});
        const results = await updateStory(id, body);
        if (results && results.status === 'OK') {
            this.setState({isUpdating: false});
            this.props.history.push(`/story/${id}`);
        } else {
            this.setState({isUpdating: false, error: results.msg || "Error while updating story. Please try again."})
        }
    };

    getStory = async (id) => {
        const {form} = this.state;
        const story = await fetchStory(id);
        Object.keys(story).forEach(field=>{
            if(Object.keys(form).includes(field)){
                form[field]['value'] = story[field]
            }
        });
        this.setState({story, form});
    }

    setStories = async (keyword, field) => {
        const {form} = this.state;
        form[field]['values'] = await getStorySuggestions(keyword);
        this.setState({form});
    }

    fetchStories = ({state, props, methods}, field) => {
        const {search} = state;
        if(search.length>=1){
            this.setStories(search, field);
        }
    }

    render() {
        const {form, isUpdating, error, addPeoplePopup, people, id, story} = this.state;
        if(_.isEmpty(story)){
            return null;
        }
        form['people']['values'] = people;
        return (
            <div className="d-flex flex-grow-1 w-100 justify-content-center align-items-center flex-column my-3">
                <h4>Add New Story</h4>
                <Form className="d-flex w-75 flex-column">
                    <div className="d-flex flex-column align-items-center">
                        {Object.keys(form).map((field, index) => {
                            let info = form[field];
                            let className = `col ${info.space === 2 ? 'col-10' : 'col-5'}`;
                            if (info.type === 'multiDropDown') {
                                info.values = info.values.map(value => {
                                    if (!value._id) {
                                        return {_id: value, name: value};
                                    }
                                    return value;
                                })
                            }
                            return (
                                <div className={className} key={index}>
                                    {info['title'] &&
                                    <Label className="font-medium">
                                        {info['title']} {info['mandatory'] ? '*' : ''}
                                    </Label>}
                                    {['text', 'number', 'textarea'].includes(info['type']) &&
                                    <Input type={info.type} className="mb-3 border-custom w-100" key={index}
                                           placeholder={info['placeHolder']} value={info['value']}
                                           onChange={(event) => this.updateValue(event, field)}
                                           invalid={info['mandatory'] ? !info['value'] : false}/>
                                    }
                                    {info['type'] === 'dropDown' &&
                                    <DropDown values={info['values']} placeHolder={info['placeHolder']} key={index}
                                              selected={info['value']}
                                              onChange={(event) => this.updateValue(event, field)}/>
                                    }
                                    {info['type'] === 'multiDropDown' &&
                                    <Select options={info.values}
                                            multi={true} clearable={true}
                                            onChange={(values) => this.updateValue(values.map(x => x._id), field, true)}
                                            labelField="name" valueField="_id" searchBy="name"
                                            values={info['value'] || []}
                                            placeholder={info['placeHolder']}/>
                                    }
                                    {
                                        field === 'people' &&
                                        <div className="btn btn-info mb-3 mt-1"
                                             onClick={() => this.setState({addPeoplePopup: true})}>
                                            <i className="fa fa-plus-circle mr-2"/>
                                            Add New People
                                        </div>
                                    }
                                    {
                                        info['type'] === 'autoComplete' &&
                                        <Select options={info.values}
                                                multi={true} clearable={true}
                                                onChange={(values) => this.updateValue(values.map(x => x._id), field, true)}
                                                labelField="title" valueField="_id" searchBy="title"
                                                searchFn={(props) => this.fetchStories(props, field)}
                                                values={info['value'] || []}
                                                placeholder={info['placeHolder']}/>
                                    }
                                </div>
                            )
                        })}
                    </div>
                    <div className="d-flex ml-0 mr-0 small w-100 justify-content-center" style={{color: 'red'}}>
                        {error}
                    </div>
                    <div className="d-flex justify-content-center">
                        <LoaderButton
                            type="button"
                            onClick={this.updateStory}
                            className="bg-info font-bold text-light m-3 px-4"
                            isLoading={isUpdating}
                            text="Update Story"
                            loadingText={`Updating Story`}
                            disabled={!this.validateForm()}
                        />
                        <NavLink to={`/story/${id}`}>
                            <div className="btn btn-secondary m-3 px-4">Cancel</div>
                        </NavLink>
                    </div>
                </Form>
                <AddPeoplePopup show={addPeoplePopup}
                                onClose={(added) => {
                                    this.setState({addPeoplePopup: false})
                                    if(added){
                                        this.getPeople();
                                    }
                                }}
                                btnText="Add People"
                                btnLoadingText="Adding People"
                                title="Add New People"/>
            </div>
        )
    }
}
