import {trackPromise} from "react-promise-tracker";
import {Auth} from "aws-amplify";

import * as _ from 'underscore';

export const callAPI = async (url, method, body={}) => {
    let headers;
    try{
        const session = await Auth.currentSession();
        headers = new Headers({'Content-Type': 'application/json', Authorization: session.idToken.jwtToken});
    } catch (e) {
        headers = new Headers({'Content-Type': 'application/json'});
    }
    try{
        const options = {headers, method};
        if(method !== 'GET' && !_.isEmpty(body)){
            options.body = JSON.stringify(body);
        }
        let response = await trackPromise(fetch(url, options));
        return await response.json();
    } catch (e) {
        console.log(e);
    }
};
