import React, {useState, useEffect} from "react";
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader} from "reactstrap";
import LoaderButton from "../../toolkit/LoaderButton";

export const AddNodePopup = ({show, onClose, title, callback, data, btnText, btnLoadingText}) => {

    const [isAdding, setIsAdding] = useState(false);
    const [name, setName] = useState(data ? data.label : "New Node");

    useEffect(() => {
        if(data && data.label){
            setName(data.label);
        }
    }, [data]);

    if(!data || !callback){
        return null;
    }

    const addNode = () => {
        setIsAdding(true);
        data.label = name;
        data.shape = 'circle';
        callback(data);
        setIsAdding(false);
        onClose();
    }

    return (
        <Modal isOpen={show}>
            <ModalHeader>
                {title}
            </ModalHeader>
            <ModalBody>
                <FormGroup controlid="nodeName">
                    <Label className="font-medium">Node Name</Label>
                    <Input value={name}
                           onChange={(e) => {setName(e.target.value)}}
                           type="text"
                           placeholder="Enter new node name"/>
                </FormGroup>
                <div className="d-flex justify-content-center">
                    <LoaderButton
                        type="button"
                        onClick={addNode}
                        className="bg-warning semi-bold text-secondary m-3 px-4 border-custom"
                        isLoading={isAdding}
                        text={btnText}
                        loadingText={btnLoadingText}
                    />
                    <Button className={`btn-danger semi-bold m-3 px-4`}
                            onClick={onClose}>Cancel</Button>
                </div>
            </ModalBody>
        </Modal>);
}
