import React, {Component} from "react";
import {NavLink, withRouter} from "react-router-dom";
import "./styles/main.scss";
import Routes from "./Routes";
import {ProfileMenu} from "./auth/Profile";
import {Auth} from "aws-amplify";
import headerLogo from "./images/header_logo.svg";
import {getRandomStoryId} from "./services/search";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            searchTerm: '',
            user: {}
        };
    }

    userHasAuthenticated = authenticated => {
        this.checkForUser();
    };

    handleLogout = async () => {
        await Auth.signOut();
        this.userHasAuthenticated(false);
        this.setState({user: null});
    };

    async componentDidMount() {
        this.checkForUser();
    }

    checkForUser = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            this.setState({user, isAuthenticated: true});
        } catch (e) {
            this.setState({isAuthenticated: false});
        }
    }

    randomStory = async () => {
        const id = await getRandomStoryId();
        if(id && id._id){
            this.props.history.push({pathname: `/story/${id._id}`, state: {random: true}});
        }
    }

    navigateToSearch = () => {
        const {searchTerm} = this.state;
        if(searchTerm && searchTerm.trim().length > 0) {
            this.props.history.push(`/search/${decodeURIComponent(searchTerm)}`);
        }
    }

    handleKeyPress = (target) => {
        if(target.charCode === 13){
            this.navigateToSearch();
        }
    }

    render() {
        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated,
            randomStory: this.randomStory
        };

        const {isAuthenticated, user} = this.state;
        const {pathname} = this.props.location;
        const noHeaderSearchPaths = ['/search', '/login', '/register'];
        const loginPaths = ['/login', '/register'];

        return (
            <div className="container col-lg-12 p-0 d-flex flex-grow-1 flex-column">
                {
                    !loginPaths.includes(pathname) &&
                    <div className="navbar fixed-top bg-primary px-5">
                        <div className="d-flex h-100 align-items-center">
                            <NavLink to="/search">
                                <img src={headerLogo} alt="rise logo"/>
                            </NavLink>
                            {
                                !noHeaderSearchPaths.includes(pathname) &&
                                <div className="ml-5 d-flex align-items-center">
                                    <div className="input-group pl-0 bg-success d-flex align-items-center pl-2 pr-3"
                                         style={{borderRadius: 24, height: 45}}>
                                        <input type="text"
                                               className="form-control bg-transparent text-dark border-0 header-search"
                                               placeholder="Type here to search"
                                               onChange={(event) => this.setState({searchTerm: event.target.value})}
                                               value={this.state.searchTerm}
                                               onKeyPress={this.handleKeyPress}/>
                                        <div className="input-group-append bg-success cursor-pointer"
                                                onClick={this.navigateToSearch}>
                                            <i className="fa fa-search" aria-hidden="true"/>
                                        </div>
                                    </div>
                                    <div onClick={this.randomStory}
                                         className="btn btn-link p-0 text-dark mx-4 text-nowrap font-medium">
                                        Random Search
                                    </div>
                                    <NavLink to={{pathname: "/advance-search", key: Math.random()}}
                                             className="text-dark text-nowrap font-medium">Advance Search</NavLink>
                                </div>
                            }
                        </div>
                        {
                            isAuthenticated && user &&
                            <div className="d-flex">
                                <NavLink to="/add-story" className="mr-3">
                                    <div className="btn btn-light text-info">
                                        <i className="fa fa-plus-circle mr-2"/> Add New Story
                                    </div>
                                </NavLink>
                                <ProfileMenu logout={this.handleLogout} user={user}/>
                            </div>
                        }
                    </div>
                }
                <div className="d-flex flex-grow-1" style={{marginTop: loginPaths.includes(pathname) ? 0 : 62}}>
                    <Routes childProps={childProps}/>
                </div>
                {
                    !loginPaths.includes(pathname) &&
                    <footer className="w-100 page-footer font-small text-center bg-light py-2 font-medium">
                        <div className="d-flex justify-content-center mx-4 table-fonts align-items-center">
                            <NavLink to="/know-us" className="mr-4 text-dark">Know Us</NavLink>
                            <span className="mr-4">●</span>
                            <NavLink to="/contact-us" className="text-dark">Get In Touch</NavLink>
                        </div>
                    </footer>
                }
            </div>
        );
    }
}

export default withRouter(App);
