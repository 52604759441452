import React, {Component} from "react";
import {Form, FormGroup, Input, Label} from "reactstrap";
import {Auth} from "aws-amplify";
import {NavLink} from "react-router-dom";

import {validateEmail, validatePassword} from "../utils/common";
import LoaderButton from "../toolkit/LoaderButton";


export default class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            email: "",
            password: "",
            name: "",
            confirmPassword: "",
            error: "",
            created: false
        };
    }

    validateForm(name, email, password, confirmPassword) {
        return (name.trim().length>0) && validateEmail(email) && validatePassword(password) && password===confirmPassword;
    }

    handleSignup = async event => {
        event.preventDefault();
        this.setState({ isLoading: true });
        const {name, email, password} = this.state;
        try {
            await Auth.signUp({username: email, password,
                attributes: {email, name}
            });
            this.setState({created: true})
        } catch (error) {
            console.log('error signing up:', error);
            this.setState({error: error.message || "error while creating account"});
        }
        this.setState({isLoading: false});
    };

    render() {
        const {name, email, password, confirmPassword, created} = this.state;
        return (
            <div className="d-flex flex-grow-1 flex-column justify-content-center align-items-center">
                <h4 className="font-medium">Create Your Account</h4>
                <div className="border border-primary rounded mt-3" style={{width: 460}}>
                    <div className="d-flex w-100 font-bold">
                        <NavLink to="/login" className="d-flex bg-primary text-dark py-3 col-6 justify-content-center">Login</NavLink>
                        <NavLink to="/register" className="d-flex py-3 col-6 text-info justify-content-center">Register</NavLink>
                    </div>
                    {
                        !created &&
                        <Form onSubmit={this.handleSignup} className="p-4 d-flex flex-column justify-content-center">
                            <FormGroup controlid="formBasicName">
                                <Label className="font-medium">Name</Label>
                                <Input value={name}
                                       onChange={(e) => {
                                           this.setState({name: e.target.value})
                                       }}
                                       type="text" required
                                       placeholder="Enter your name"/>
                            </FormGroup>

                            <FormGroup controlid="formBasicEmail">
                                <Label className="font-medium">Email</Label>
                                <Input value={email}
                                       onChange={(e) => {
                                           this.setState({email: e.target.value})
                                       }}
                                       type="email" required
                                       placeholder="Enter your email"/>
                            </FormGroup>

                            <FormGroup controlid="formBasicPassword">
                                <Label className="font-medium">Password</Label>
                                <Input
                                    value={password}
                                    onChange={(e) => {
                                        this.setState({password: e.target.value})
                                    }}
                                    type="password" required
                                    placeholder="Enter your password"/>
                            </FormGroup>

                            <FormGroup controlid="formBasicConfirmPassword">
                                <Label className="font-medium">Confirm Password</Label>
                                <Input
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        this.setState({confirmPassword: e.target.value})
                                    }}
                                    type="password" required
                                    placeholder="Confirm password"/>
                            </FormGroup>

                            <div className="ml-0 mr-0 small text-warning">
                                {
                                    password !== "" && !validatePassword(password) &&
                                    "Password must contain atleast 8 characters including uppercase, lowercase, number, and a special character."
                                }
                                {
                                    this.state.error
                                }
                            </div>

                            <LoaderButton
                                disabled={!this.validateForm(name, email, password, confirmPassword)}
                                type="submit"
                                isLoading={this.state.isLoading}
                                text="Register"
                                loadingText="Signing up"
                                className="btn-info w-fit-content align-self-center mt-2"
                            />
                        </Form>
                    }
                    {
                        created &&
                            <div style={{height: 480}} className="d-flex justify-content-center align-items-center">
                                <span>
                                    Your account has been created. Click <NavLink to="/login" className="text-info">here</NavLink> to login.
                                </span>
                            </div>
                    }
                </div>
            </div>
        );
    }
}
