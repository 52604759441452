import {callAPI} from "./wrapper";
import {BASE_URL, MOCK} from "../utils/constants";
import {sleep} from "../utils/common";
import {getMockPeople, getMockRandomStoryId} from "../utils/mockResponses";

export async function fetchPeople() {
    if(MOCK){
        await sleep(1000);
        return getMockPeople();
    }
    return await callAPI(BASE_URL + `people`, 'GET');
}

export async function createPeople(body) {
    if(MOCK){
        await sleep(1000);
        return getMockRandomStoryId();
    }
    return await callAPI(BASE_URL + `people`, 'POST', body);
}

export async function editPeople(body, id) {
    if(MOCK){
        await sleep(1000);
        return getMockRandomStoryId();
    }
    return await callAPI(BASE_URL + `people/${id}`, 'PATCH', body);
}
