import React, {Component} from "react";
import {NavLink} from "react-router-dom";

export default class KnowUs extends Component {
    render() {
        return(
            <div className="d-flex flex-grow-1 flex-row w-100">
                <div className="d-flex w-50 know-us-image">
                </div>
                <div className="px-5 d-flex flex-column justify-content-center" style={{flexBasis: '50%'}}>
                    <h4 className="font-bold">About RISE</h4>
                    <p className="mt-5" style={{lineHeight: '33px', letterSpacing: 0}}>
                        <strong className="font-bold">The Research and Innovation Story Enterprise (RISE)</strong> is an easily accessible database of fundamental research success stories depicted in a way that highlights the connections between different R&D efforts. This effort seeks to illuminate the role of fundamental research in the process of scientific and technological innovation while creating a tool that will aid STEM education and outreach efforts, inform S&T policymakers, and provide insight into the broader impacts of research. The project is currently in the prototype stage.
                    </p>
                    <p className="mt-3 font-bold">
                        <strong>If you are interested in providing feedback or getting involved,</strong> <br/>
                        <span className="text-info">
                            please <NavLink to="/contact-us" className="text-info"><u>contact us</u></NavLink>
                        </span>
                    </p>
                    <p className="mt-3">
                        <strong className="font-bold">For inquires about this project,</strong> <br/>
                        <span>
                            Please contact Kushal Seetharam at
                            <a href="mailto:kis@mit.edu" className="font-bold text-info ml-1">kis@mit.edu</a>
                        </span>
                    </p>
                </div>
            </div>
        );
    }
}
