import React, {useEffect, useState} from "react";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import LoaderButton from "../toolkit/LoaderButton";

export const Confirmation = ({show, onClose, onAction, title, msg, buttonText, actionButtonText, data}) => {

    const confirmAction = async () => {
        setIsConfirming(true);
        onAction(deleteData);
        setIsConfirming(false);
        onClose();
    }

    const [isConfirming, setIsConfirming] = useState(false);
    const [deleteData, setDeleteData] = useState(data);

    useEffect(() => {
        setDeleteData(data);
    }, [data]);

    return (
        <Modal isOpen={show}>
            <ModalHeader>
                {title}
            </ModalHeader>
            <ModalBody>
                <div className="text-center font-18 mb-3" dangerouslySetInnerHTML={{__html: msg}}/>
                <div className="d-flex justify-content-center">
                    <Button className={`btn-warning semi-bold m-3 px-4`}
                            onClick={onClose}>Cancel</Button>
                    {
                        onAction && <LoaderButton
                            type="button"
                            onClick={confirmAction}
                            className="btn-danger m-3 px-4"
                            isLoading={isConfirming}
                            text={buttonText}
                            loadingText={actionButtonText}
                        />
                    }
                </div>
            </ModalBody>
        </Modal>);
}
