import React, {useState, useEffect} from "react";
import Graph from "react-graph-vis";
import {AddNodePopup} from "./AddNodePopup";
import {AddEdgePopup} from "./AddEdgePopup";
import {Confirmation} from "../../toolkit/Confirmation";

const areEqual = (prevProps, nextProps) => prevProps.isAuthenticated===nextProps.isAuthenticated &&
        prevProps.storyId===nextProps.storyId && prevProps.latestKey===nextProps.latestKey;

const GraphMemo = React.memo(({graph, events, options, isAuthenticated, childRef, storyId, latestKey})=>{
    return (
        <Graph
            key={isAuthenticated + latestKey}
            ref={childRef}
            graph={graph}
            events={events}
            options={options}/>
    );
}, areEqual);

export const StoryGraphView = ({story, isAuthenticated, childRef, latestKey, clickEvent}) => {

    const [addData, setAddData] = useState(null);
    const [addCallback, setAddCallback] = useState(null);
    const [addNodePopup, setAddNodePopup] = useState(false);
    const [editNodePopup, setEditNodePopup] = useState(false);

    const [addEdgeData, setAddEdgeData] = useState(null);
    const [addEdgeCallback, setAddEdgeCallback] = useState(null);
    const [addEdgePopup, setAddEdgePopup] = useState(false);
    const [editEdgePopup, setEditEdgePopup] = useState(false);

    const [deleteNodeData, setDeleteNodeData] = useState(null);
    const [deleteNodeCallback, setDeleteNodeCallback] = useState(null);
    const [deleteNodePopup, setDeleteNodePopup] = useState(false);

    const events = {
        click:  clickEvent
    };

    const manipulation = {
        initiallyActive: false,
        addNode: (data, callback) => {
            setAddData(data);
            setAddCallback(()=>callback);
            setAddNodePopup(true);
        },
        editNode: function (data, callback) {
            setAddData(data);
            setAddCallback(()=>callback);
            setEditNodePopup(true);
        },
        addEdge: function (data, callback) {
            setAddEdgeData(data);
            setAddEdgeCallback(()=>callback);
            setAddEdgePopup(true);
        },
        editEdge: function (data, callback) {
            setAddEdgeData(data);
            setAddEdgeCallback(()=>callback);
            setEditEdgePopup(true);
        },
        deleteNode: function (data, callback) {
            console.log(data);
            if(!data.nodes.includes(story._id)){
                setDeleteNodeData(data);
            }
            setDeleteNodeCallback(()=>callback);
            setDeleteNodePopup(true);
        }
    };
    const options = {
        locale: "en",
        layout: {
            randomSeed: 5,
        },
        ...(isAuthenticated ? {manipulation} : {})
    };
    return (
        <div id='graph' className="d-flex flex-grow-1">
            <GraphMemo options={options} events={events} graph={story.graph} isAuthenticated={isAuthenticated}
                       storyId={story.id} childRef={childRef} latestKey={latestKey}/>

            <AddNodePopup data={addData}
                          show={addNodePopup || editNodePopup}
                          callback={addCallback}
                          onClose={()=>{
                              addCallback(null);
                              setAddCallback(null);
                              setAddData(null);
                              setAddNodePopup(false);
                          }}
                          btnText={addNodePopup ? "Add Node" : "Edit Node"}
                          btnLoadingText={addNodePopup ? "Adding Node" : "Editing Node"}
                          title={addNodePopup ? "Add New Node" : "Edit Node"}/>

            <AddEdgePopup data={addEdgeData}
                          show={addEdgePopup || editEdgePopup}
                          callback={addEdgeCallback}
                          onClose={()=>{
                              addEdgeCallback(null);
                              setAddEdgeCallback(null);
                              setAddEdgeData(null);
                              setAddEdgePopup(false);
                          }}
                          btnText={addEdgePopup ? "Add" : "Edit"}
                          btnLoadingText={addEdgePopup ? "Adding" : "Editing"}
                          title={addEdgePopup ? "Select Relationship" : "Edit Relationship"}/>
            <Confirmation title={"Delete Node"}
                          show={deleteNodePopup}
                          buttonText={"Delete"}
                          actionButtonText={"Deleting"}
                          data={deleteNodeData}
                          msg={deleteNodeData ? "Are you sure you want to delete node?" : "Cannot delete this node."}
                          onAction={deleteNodeData ? deleteNodeCallback: null}
                          onClose={()=>{
                              deleteNodeCallback(null);
                              setDeleteNodeCallback(null);
                              setDeleteNodeData(null);
                              setDeleteNodePopup(false);
                          }}/>

        </div>
    );
}
