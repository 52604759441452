import React, {Component} from "react";
import {Button, Form, FormGroup, Input, Label} from 'reactstrap';
import {sendContactDetails} from "../../services/contact";

export default class ContactUs extends Component {
    constructor() {
        super();
        this.state = {
            name: '',
            email: '',
            message: ''
        }
    }

    contactUs = async () => {
        const {name, email, message} = this.state;
        await sendContactDetails({name, email, message});
    }

    render() {
        const {name, email, message} = this.state;
        return(
            <div className="d-flex flex-grow-1 flex-row w-100">
                <div className="d-flex w-50 contact-us-image"
                     style={{backgroundSize: 'cover'}}>
                </div>
                <div className="px-5 d-flex flex-column justify-content-center" style={{flexBasis: '50%'}}>
                    <h4 className="font-bold">Get In Touch </h4>
                    <Form className="mt-4">
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Input type="text" value={name} required
                                   onChange={(e)=>this.setState({name: e.target.value})}/>
                        </FormGroup>
                        <FormGroup className="mt-2">
                            <Label for="email">Email</Label>
                            <Input type="email" id="email" value={email} requird
                                   onChange={(e)=>this.setState({email: e.target.value})}/>
                        </FormGroup>
                        <FormGroup className="mt-2">
                            <Label for="message">Message</Label>
                            <Input type="textarea" id="message" value={message} required
                                   onChange={(e)=>this.setState({message: e.target.value})}/>
                        </FormGroup>
                        <Button className="mt-2 btn-info" onClick={this.contactUs}>Send Message</Button>
                    </Form>
                    <p className="mt-5">
                        <strong className="font-bold">For inquires about this project,</strong> <br/>
                        <span>
                            Please contact Kushal Seetharam at
                            <a href="mailto:kis@mit.edu" className="font-bold text-info ml-1">kis@mit.edu</a>
                        </span>
                    </p>
                </div>
            </div>
        );
    }
}
