// checks that the email field contains an "@" and a "." in the 4th index from the end. can be improved.
export function validateEmail(email) {
    return /\S+@\S+\.\S+/.test(email);

}

//checks that a password contains at least 8 characters, a capital, a lowercase, a number, and a special character
export function validatePassword(password) {
    return /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(password);
}

export const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

export function randomNumber(min, max) {
    return (Math.random() * (max - min) + min).toFixed(0);
}
