import {callAPI} from "./wrapper";
import {BASE_URL, MOCK} from "../utils/constants";
import {sleep} from "../utils/common";
import {getMockRandomStoryId, getMockStory} from "../utils/mockResponses";

export async function fetchStory(id) {
    if(MOCK){
        await sleep(1000);
        return getMockStory(id);
    }
    return await callAPI(BASE_URL + `story/${id}`, 'GET');
}

export async function createStory(body) {
    if(MOCK){
        await sleep(1000);
        return getMockRandomStoryId();
    }
    return await callAPI(BASE_URL + `story`, 'POST', body);
}

export async function saveGraph(body) {
    if(MOCK){
        await sleep(1000);
        return {status: 'OK'};
    }
    return await callAPI(BASE_URL + `story`, 'PATCH', body);
}

export async function updateStory(id, body) {
    if(MOCK){
        await sleep(1000);
        return getMockStory('random-id');
    }
    return await callAPI(BASE_URL + `story/${id}`, 'PATCH', body);
}

export async function deleteStory(id) {
    if(MOCK){
        await sleep(1000);
        return {status: 'OK'};
    }
    return await callAPI(BASE_URL + `story/${id}`, 'DELETE');
}
