import {BASE_URL, MOCK} from "../utils/constants";
import {sleep} from "../utils/common";
import {
    getMockAdvanceSearchResults,
    getMockRandomStoryId,
    getMockSearchResults,
    getMockSuggestions
} from "../utils/mockResponses";
import {callAPI} from "./wrapper";

export async function getRandomStoryId() {
    if(MOCK){
        await sleep(200);
        return getMockRandomStoryId();
    }
    return await callAPI(BASE_URL + 'search/random', 'GET')
}

export async function advanceSearch(body) {
    if(MOCK){
        await sleep(1000);
        return getMockAdvanceSearchResults();
    }
    return await callAPI(BASE_URL + 'search/advance', 'POST', body);
}

export async function getStorySuggestions(keyword) {
    if(MOCK){
        await sleep(500);
        return getMockSuggestions(keyword);
    }
    return await callAPI(BASE_URL + `search?keyword=${keyword}`, 'GET');
}

export async function getSearchResults(keyword) {
    if(MOCK){
        await sleep(500);
        return getMockSearchResults(keyword);
    }
    return await callAPI(BASE_URL + `search?keyword=${keyword}&description=true`, 'GET');
}
