import React, {Component} from "react";
import {Form, FormGroup, Input, Label} from "reactstrap";
import {Auth} from "aws-amplify";
import {NavLink} from "react-router-dom";

import {validateEmail, validatePassword} from "../utils/common";
import LoaderButton from "../toolkit/LoaderButton";


export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            email: "",
            password: ""
        };
    }

    validateForm(email, password) {
        return validateEmail(email) && validatePassword(password);
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleLogin = async event => {
        event.preventDefault();
        this.setState({ isLoading: true });
        try {
            await Auth.signIn(this.state.email, this.state.password);
            this.props.userHasAuthenticated(true);
        } catch (e) {
            console.log(e.message);
            this.setState({error: e.message});
        }
        this.setState({ isLoading: false });
    };

    render() {
        return (
            <div className="d-flex flex-grow-1 flex-column justify-content-center align-items-center">
                <h4 className="font-medium">Sign In To Your Account</h4>
                <div className="border border-primary rounded mt-3" style={{width: 460}}>
                    <div className="d-flex w-100 font-bold">
                        <NavLink to="/login" className="d-flex text-info py-3 col-6 justify-content-center">Login</NavLink>
                        <NavLink to="/register" className="d-flex py-3 col-6 bg-primary text-dark justify-content-center">Register</NavLink>
                    </div>
                    <Form onSubmit={this.handleLogin} className="p-4 d-flex flex-column justify-content-center">
                        <FormGroup controlid="formBasicEmail">
                            <Label className="font-medium">Email</Label>
                            <Input value={this.state.email}
                                   onChange={(e) => {
                                              this.setState({email: e.target.value})
                                          }}
                                   type="email" required
                                   placeholder="Enter your email"/>
                        </FormGroup>

                        <FormGroup controlid="formBasicPassword">
                            <Label className="font-medium">Password</Label>
                            <Input
                                          value={this.state.password}
                                          onChange={(e) => {
                                              this.setState({password: e.target.value})
                                          }}
                                          type="password" required
                                          placeholder="Enter your password"/>
                        </FormGroup>

                        <div className="ml-0 mr-0 small text-warning">
                            {
                                this.state.password !== "" && !validatePassword(this.state.password) &&
                                    "Password must contain atleast 8 characters including uppercase, lowercase, number, and a special character."
                            }
                            {
                                this.state.error
                            }
                        </div>

                        <LoaderButton
                            disabled={!this.validateForm(this.state.email, this.state.password)}
                            type="submit"
                            isLoading={this.state.isLoading}
                            text="Log in"
                            loadingText="Signing in"
                            className="btn-info w-fit-content align-self-center mt-2"
                        />
                    </Form>
                </div>
            </div>
        );
    }
}
