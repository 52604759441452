import React from "react";
import { Route, Redirect } from "react-router-dom";

export default ({ component: C, props: cProps, extraProps, ...rest }) =>
	<Route
		{...rest}
		render={props =>
		cProps.isAuthenticated
			? <C {...props} {...cProps} {...extraProps}/>
			: <Redirect
				to={`/login?redirect=${props.location.pathname}${props.location.search}`}
			/>}
	/>;
