import React, {Component} from "react";
import {Form, Input, Label} from 'reactstrap';
import {advanceSearchFields} from "../../utils/mapping";
import {advanceSearch} from "../../services/search";
import {DropDown} from "../../toolkit/DropDown";
import Select from "react-dropdown-select";
import {NavLink} from "react-router-dom";
import LoaderButton from "../../toolkit/LoaderButton";
import * as _ from 'underscore';
import {SearchResultBlock} from "./SearchResultBlock";

export default class AdvanceSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: JSON.parse(JSON.stringify(advanceSearchFields)),
            isSearching: false,
            results: null,
            latestKey: 1
        }
    }

    validateForm = () => {
        const {form} = this.state;
        let valid = true;
        Object.keys(form).forEach(field => {
            valid = valid && (form[field]['mandatory'] ? form[field]['value'] : true);
        });
        return valid;
    };

    updateValue = (event, field, isValue = false) => {
        const {form} = this.state;
        if (isValue) {
            form[field]['value'] = event;
        } else {
            form[field]['value'] = event.target.value;
        }
        this.setState({form: form});
    };

    search = async (event) => {
        event.preventDefault();
        const body = {};
        Object.keys(this.state.form).forEach(field => {
            if (this.state.form[field]['value']) {
                body[field] = this.state.form[field]['value'];
            }
        });
        if (_.isEmpty(body)) {
            this.setState({error: 'Please fill value for at-least one field to search'})
            return;
        }
        this.setState({isSearching: true});
        const results = await advanceSearch(body);
        if (results.length > 0) {
            this.setState({isSearching: false, results});
        } else {
            this.setState({isSearching: false, error: "No results found. Try again with different values."})
        }
    };

    render() {
        const {form, isSearching, error, results, latestKey} = this.state;

        return (
            <div
                className={`d-flex flex-grow-1 w-100 justify-content-center ${results && results.length > 0 ? '' : 'align-items-center'}`}>
                {
                    !(results && results.length > 0) &&
                    <Form className="d-flex w-75 flex-column" key={latestKey}>
                        <div className="row">
                            {Object.keys(form).map((field, index) => {
                                let info = form[field];
                                let className = `col ${info.space === 1 ? 'col-6' : 'col-3'}`;
                                if (info.type === 'multiDropDown') {
                                    info.values = info.values.map(value => {
                                        if (!value._id) {
                                            return {_id: value, name: value};
                                        }
                                        return value;
                                    })
                                }
                                return (
                                    <div className={className} key={index}>
                                        {info['title'] &&
                                        <Label className="font-medium">
                                            {info['title']} {info['mandatory'] ? '*' : ''}
                                        </Label>}
                                        {['text', 'number'].includes(info['type']) &&
                                        <Input type={info.type} className="mb-3 border-custom w-100" key={index}
                                               placeholder={info['placeHolder']} value={info['value']}
                                               onChange={(event) => this.updateValue(event, field)}
                                               invalid={info['mandatory'] ? !info['value'] : false}/>
                                        }
                                        {info['type'] === 'dropDown' &&
                                        <DropDown values={info['values']} placeHolder={info['placeHolder']} key={index}
                                                  selected={info['value']}
                                                  onChange={(event) => this.updateValue(event, field)}/>
                                        }
                                        {info['type'] === 'multiDropDown' &&
                                        <Select options={info.values}
                                                multi={true} clearable={true}
                                                onChange={(values) => this.updateValue(values.map(x => x._id), field, true)}
                                                labelField="name" valueField="_id" searchBy="name"
                                                values={info['value'] || []}
                                                placeholder={info['placeHolder']}/>
                                        }
                                    </div>
                                )
                            })}
                        </div>
                        <div className="d-flex ml-0 mr-0 small w-100 justify-content-center" style={{color: 'red'}}>
                            {error}
                        </div>
                        <div className="d-flex justify-content-center">
                            <LoaderButton
                                type="button"
                                onClick={this.search}
                                className="bg-info font-bold text-light m-3 px-4"
                                isLoading={isSearching}
                                text="Search"
                                loadingText={`Searching`}
                                disabled={!this.validateForm()}
                            />
                            <NavLink to="/search">
                                <div className="btn btn-secondary m-3 px-4">Cancel</div>
                            </NavLink>
                            <div className="btn bg-secondary m-3 px-4"
                                 onClick={() => this.setState({
                                     form: JSON.parse(JSON.stringify(advanceSearchFields)),
                                     latestKey: latestKey + 1
                                 })}>
                                Reset
                            </div>
                        </div>
                    </Form>
                }
                {
                    results && results.length > 0 &&
                    <div className="d-flex flex-column flex-grow-1 px-5 mx-5 mt-4">
                        <h4 className="mb-0">{`Advance Search Results`}</h4>
                        {
                            results && results.length > 0 &&
                            <span
                                className="small text-warning mb-4">{`About ${results.length} results match your search`}</span>
                        }
                        {
                            results && results.length > 0 &&
                            <SearchResultBlock results={results}/>
                        }
                    </div>
                }
            </div>
        )
    }
}
