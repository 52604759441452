import React, {Component, Fragment} from "react";
import {parse} from 'query-string';
import {deleteStory, fetchStory, saveGraph} from "../../services/story";
import {StoryView} from "./StoryView";
import {StoryGraphView} from "../graph/GraphView";
import LoaderButton from "../../toolkit/LoaderButton";
import {Button} from "reactstrap";
import {NavLink} from "react-router-dom";
import {Confirmation} from "../../toolkit/Confirmation";

export default class Story extends Component {
    constructor(props) {
        super(props);
        const parsed = parse(props.location.search);
        const view = parsed['view'] && ['story', 'graph'].includes(parsed['view']) ? parsed['view'] : 'story';
        this.state = {
            id: props.match.params.id,
            view,
            story: null,
            random: props.location.state && props.location.state.random,
            isSaving: false,
            latestKey: 1,
            editMode: false,
            deleteStoryConfirmation: false
        }
        this.graphRef = null;
    }

    componentDidMount() {
        this.getStory(this.state.id);
    }

    componentDidUpdate(prevProps) {
        const parsed = parse(this.props.location.search);
        const view = parsed['view'] && ['story', 'graph'].includes(parsed['view']) ? parsed['view'] : 'story';
        if (this.state.id !== this.props.match.params.id) {
            this.setState({
                id: this.props.match.params.id,
                random: this.props.location.state && this.props.location.state.random
            });
            this.getStory(this.props.match.params.id);
        } else if (view !== this.state.view) {
            this.setState({view});
        }
    }

    changeView = (view) => {
        const {id} = this.state;
        this.props.history.push(`/story/${id}?view=${view}`);
    }

    getStory = async (id) => {
        const story = await fetchStory(id);
        this.setState({story});
    }

    updateGraph = async () => {
        const {story} = this.state;
        if (this.graphRef && this.graphRef.Network) {
            this.setState({isSaving: true});
            const edges = Object.values(this.graphRef.Network.body.data.edges._data);
            const nodes = Object.values(this.graphRef.Network.body.data.nodes._data);
            const stories = [];
            nodes.forEach(node => {
                const story = {id: node.id, title: node.label};
                story.parents = edges.filter(edge => edge.to === node.id && edge.arrows !== '').map(edge => edge.from);
                story.associates = edges.filter(edge => (edge.to === node.id || edge.from === node.id) && edge.arrows === '').map(
                    edge => edge.from === node.id ? edge.to : edge.from);
                stories.push(story);
            });
            await saveGraph(stories);
            this.graphRef.Network.disableEditMode();
            this.getStory(story._id);
            this.setState({isSaving: false, editMode: false});
        }
    }

    resetGraph = () => {
        if(this.graphRef && this.graphRef.Network){
            this.graphRef.Network.disableEditMode();
        }
        this.setState({latestKey: this.state.latestKey + 1, editMode: false});
    }

     clickEvent = (event) => {
        if(!this.state.editMode) {
            console.log(event);
            const { nodes } = event;
            if(nodes.length === 1){
                this.props.history.push(`/story/${nodes[0]}?view=graph`)
            }
        }
    }

    editGraph = () => {
        if(this.graphRef && this.graphRef.Network){
            this.graphRef.Network.enableEditMode();
        }
        this.setState({editMode: true});
    }

    deleteStory = async() => {
        const {story} = this.state;
        await deleteStory(story._id);
        this.props.history.push(`/search`);
    }

    render() {
        const {story, view, random, isSaving, latestKey, editMode, id, deleteStoryConfirmation} = this.state;
        if (!story) {
            return null;
        }
        return (
            <div className="d-flex flex-column flex-grow-1 px-5 mx-5 mt-4">
                <div className="d-flex flex-grow-1 flex-column">
                    <div className="d-flex justify-content-between w-100 align-items-center py-4">
                        <div className="d-flex flex-column flex-wrap" style={{flexBasis: '60%'}}>
                            <div className="d-flex align-items-center">
                                <div className="d-flex font-bold h5">{story.title}</div>
                                {
                                    random &&
                                    <div className="d-flex small bg-secondary py-1 px-2 ml-2"
                                         style={{borderRadius: 10}}>
                                        Random Search
                                    </div>
                                }
                                {
                                    this.props.isAuthenticated &&
                                        <Fragment>
                                            <NavLink className="ml-3 small d-flex align-items-center" to={`/story/${id}/edit`}>
                                                <i className="fa fa-edit"/>
                                            </NavLink>
                                            <div className="ml-3 small d-flex align-items-center cursor-pointer"
                                                 onClick={()=>this.setState({deleteStoryConfirmation: true})}>
                                                <i className="fa fa-trash"/>
                                            </div>
                                        </Fragment>
                                }
                            </div>
                            <div className="small text-warning">{story.description}</div>
                        </div>
                        <div className="d-flex mx-4 justify-content-end" style={{flexBasis: '40%'}}>
                            <div
                                className={`btn btn-secondary mr-3 ${view === 'graph' ? 'disabled' : 'bg-transparent'}`}
                                onClick={() => this.changeView('graph')}>
                                Graph View
                            </div>
                            <div
                                className={`btn btn-secondary ${view === 'story' ? 'disabled' : 'bg-transparent'}`}
                                onClick={() => this.changeView('story')}>
                                Story View
                            </div>
                        </div>
                    </div>
                    {
                        view === 'story' &&
                        <StoryView story={story}/>
                    }
                    {
                        view === 'graph' &&
                        <div className="d-flex flex-column flex-grow-1 position-relative mr-4">
                            {
                                this.props.isAuthenticated && editMode &&
                                <div className="d-flex mb-1">
                                    <LoaderButton
                                        type="button"
                                        className="bg-info font-bold text-light py-1 px-4"
                                        isLoading={isSaving}
                                        text="Save"
                                        onClick={this.updateGraph}
                                        loadingText={`Saving`}
                                    />
                                    <Button className="btn-danger semi-bold ml-3 px-4 py-1"
                                            onClick={this.resetGraph}>Cancel</Button>
                                </div>
                            }
                            {
                                this.props.isAuthenticated && !editMode &&
                                <div className="d-flex mb-1">
                                    <Button className="btn-info font-bold text-light px-4 py-1"
                                            onClick={this.editGraph}>Edit</Button>
                                </div>
                            }
                            <StoryGraphView story={story} isAuthenticated={this.props.isAuthenticated}
                                            latestKey={latestKey} clickEvent={this.clickEvent}
                                            childRef={ref => (this.graphRef = ref)} {...this.props}/>
                        </div>
                    }
                </div>
                <Confirmation title={"Delete Story"}
                              show={deleteStoryConfirmation}
                              buttonText={"Delete"}
                              actionButtonText={"Deleting"}
                              msg={`Are you sure you want to delete story ${story.title}?`}
                              onAction={this.deleteStory}
                              onClose={()=>{
                                  this.setState({deleteStoryConfirmation: false})
                              }}/>
            </div>
        )
    }
}
