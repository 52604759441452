import {NavLink} from "react-router-dom";
import React from "react";

export const SearchResultBlock = ({results}) => {
    return (
        results.map((result, index) => {
            return (
                <NavLink to={`/story/${result._id}?view=story`} key={index}>
                    <div
                         className="d-flex justify-content-between w-100 align-items-center p-4 border-primary border mb-2">
                        <div className="d-flex flex-column flex-wrap" style={{flexBasis: '60%'}}>
                            <div className="font-bold">{result.title}</div>
                            <div className="small text-warning">{result.description}</div>
                        </div>
                        <div className="d-flex mx-4 justify-content-end" style={{flexBasis: '40%'}}>
                            <NavLink to={`/story/${result._id}?view=graph`}>
                                <div className="btn btn-secondary mr-3">
                                    View Graph
                                </div>
                            </NavLink>
                            <NavLink to={`/story/${result._id}?view=story`}>
                                <div className="btn btn-secondary">
                                    View Story
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </NavLink>
            );
        })
    )
}
