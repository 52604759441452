import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import AuthenticatedRoute from "./auth/AuthenticatedRoute";
import NotFound from "./auth/NotFound";
import UnauthenticatedRoute from "./auth/UnauthenticatedRoute";
import SearchPage from "./components/search/Search";
import AddStory from "./components/story/AddStory";
import KnowUs from "./components/static/KnowUs";
import ContactUs from "./components/static/ContactUs";
import SearchResult from "./components/search/SearchResult";
import Story from "./components/story/Story";
import RouteWrapper from "./auth/RouteWrapper";
import AdvanceSearch from "./components/search/AdvanceSearch";
import Login from "./auth/Login";
import Signup from "./auth/Signup";
import EditStory from "./components/story/EditStory";

const routes = ({ childProps }) =>
    <Switch>
        <RouteWrapper path="/search" exact component={SearchPage} props={childProps}/>
        <RouteWrapper path="/advance-search" component={AdvanceSearch} props={childProps} key={Math.random()}/>
        <RouteWrapper path="/search/:keyword" exact component={SearchResult} props={childProps}/>
        <RouteWrapper path="/story/:id" exact component={Story} props={childProps}/>
        <AuthenticatedRoute path="/story/:id/edit" exact component={EditStory} props={childProps}/>
        <RouteWrapper path="/know-us" exact component={KnowUs} props={childProps}/>
        <RouteWrapper path="/contact-us" exact component={ContactUs} props={childProps}/>
        <AuthenticatedRoute path="/add-story" exact component={AddStory} props={childProps}/>
        <UnauthenticatedRoute path="/login" exact component={Login} props={childProps}/>
        <UnauthenticatedRoute path="/register" exact component={Signup} props={childProps}/>
        <Redirect from="/" exact to="/search"/>
        <Route component={NotFound}/>
    </Switch>;

export default routes;
