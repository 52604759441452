import {Popover, PopoverHeader, PopoverBody} from "reactstrap";
import React, {Fragment, useState} from "react";
import profileImage from "../images/profile.png";

export const ProfileMenu = ({logout, user}) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const toggle = () => setPopoverOpen(!popoverOpen);

    return (
        <Fragment>
            <div className="d-flex cursor-pointer align-items-center" id="profilePopover">
                <div className="pointer-events-none">
                    <img src={profileImage} className="rounded-circle"
                         alt="profile" height={34} width={34}/>
                </div>
                <div className="mx-2 pointer-events-none text-nowrap">
                    {user.attributes ? user.attributes.name : ""}
                </div>
                <div className="ml-2">
                    <i className="fa fa-angle-down font-weight-bolder"/>
                </div>
            </div>
            <Popover placement="bottom" isOpen={popoverOpen} target="profilePopover" toggle={toggle} trigger="legacy">
                <PopoverHeader className="text-dark px-3" style={{fontSize: 14}}>
                    <span>{user.attributes.email}</span>
                </PopoverHeader>
                <PopoverBody>
                    <span onClick={logout} className="p-2 cursor-pointer">Logout</span>
                </PopoverBody>
            </Popover>
        </Fragment>
    );
}
