import React, {Component} from "react";
import {Button, Form, Input, Label, Modal, ModalBody, ModalHeader} from "reactstrap";
import LoaderButton from "../toolkit/LoaderButton";
import {DropDown} from "./DropDown";
import Select from "react-dropdown-select";
import {addPeopleFields} from "../utils/mapping";
import {editPeople} from "../services/people";
import * as _ from "underscore";

export class EditPeoplePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
            people: props.people,
            form: JSON.parse(JSON.stringify(addPeopleFields))
        }
    }

    componentDidMount() {
        this.setForm();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!_.isEqual(this.state.people, this.props.people)){
            this.setState({people: this.props.people}, this.setForm);
        }
    }

    setForm = () => {
        let {people, form} = this.state;
        Object.keys(people).forEach(field=>{
            if(Object.keys(form).includes(field)){
                form[field]['value'] = people[field]
            }
        });
        this.setState({form});
    }

    edit = async (event) => {
        event.preventDefault();
        const {form, people} = this.state;
        this.setState({isEditing: true});
        const body = {};
        Object.keys(form).forEach(field => {
            if (form[field]['value']) {
                body[field] = form[field]['value'];
            }
        });
        await editPeople(body, people._id);
        this.setState({isEditing: false, form: JSON.parse(JSON.stringify(addPeopleFields))});
        this.props.onClose(true);
    }

    updateValue = (event, field, isValue = false) => {
        const {form} = this.state;
        if (isValue) {
            form[field]['value'] = event;
        } else {
            form[field]['value'] = event.target.value;
        }
        this.setState({form});
    };

    validateForm = () => {
        const {form} = this.state;
        let valid = true;
        Object.keys(form).forEach(field => {
            valid = valid && (form[field]['mandatory'] ? form[field]['value'] : true);
        });
        return valid;
    };

    render() {
        const {show, onClose, title, btnText, btnLoadingText} = this.props;
        const {isEditing, form} = this.state;
        return (
            <Modal isOpen={show}>
                <ModalHeader>
                    {title}
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={this.edit} className="d-flex flex-column">
                        <div className="d-flex flex-column align-items-center">
                            {Object.keys(form).map((field, index) => {
                                let info = form[field];
                                if (info.type === 'multiDropDown') {
                                    info.values = info.values.map(value => {
                                        if (!value.id) {
                                            return {id: value, value};
                                        }
                                        return value;
                                    })
                                }
                                return (
                                    <div className="col" key={index}>
                                        {info['title'] &&
                                        <Label className="font-medium">
                                            {info['title']} {info['mandatory'] ? '*' : ''}
                                        </Label>}
                                        {['text', 'number', 'textarea'].includes(info['type']) &&
                                        <Input type={info.type} className="mb-3 border-custom w-100" key={index}
                                               placeholder={info['placeHolder']} value={info['value']}
                                               onChange={(event) => this.updateValue(event, field)}
                                               invalid={info['mandatory'] ? !info['value'] : false}/>
                                        }
                                        {info['type'] === 'dropDown' &&
                                        <DropDown values={info['values']} placeHolder={info['placeHolder']} key={index}
                                                  selected={info['value']}
                                                  onChange={(event) => this.updateValue(event, field)}/>
                                        }
                                        {info['type'] === 'multiDropDown' &&
                                        <Select options={info.values}
                                                className="mb-3"
                                                multi={true} clearable={true}
                                                onChange={(values) => this.updateValue(values.map(x => x.id), field, true)}
                                                labelField="value" valueField="id" searchBy="value"
                                                values={info['value'] || []} addPlaceholder="+ click to add"
                                                placeholder={info['placeHolder']}/>
                                        }
                                    </div>
                                )
                            })}
                        </div>
                        <div className="d-flex justify-content-center">
                            <LoaderButton
                                type="submit"
                                className="bg-warning semi-bold text-secondary m-3 px-4 border-custom"
                                isLoading={isEditing}
                                text={btnText}
                                loadingText={btnLoadingText}
                                disabled={!this.validateForm()}
                            />
                            <Button className="btn-danger semi-bold m-3 px-4"
                                    onClick={()=> {
                                        this.setState({form: JSON.parse(JSON.stringify(addPeopleFields))});
                                        onClose(false);
                                    }}>Cancel</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>);
    }
}
