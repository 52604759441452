import React from "react";
import pdfIcon from "../../images/pdf_icon.svg";
import tickMarkImage from "../../images/green_tickmaak.svg";
import {NavLink} from "react-router-dom";
import {pdf} from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import {PdfView} from "./PdfVIew";
import * as _ from "underscore";
import {storyExcludedFields, storyView} from "../../utils/mapping";

const downloadPdf = async (story) => {
    const doc = <PdfView story={story}/>;
    const asPdf = pdf([]); // {} is important, throws without an argument
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `${story.title}.pdf`);
};

export const StoryView = ({story}) => {
    return (
        <div className="d-flex flex-column flex-grow-1">
            <div className="d-flex flex-wrap">
                <div
                    className="d-flex flex-row border border-primary rounded p-3 mr-3 mt-3 justify-content-between story-boxes">
                    <div className="d-flex flex-column">
                        <div className="font-bold">{story.title}</div>
                        {
                            story.shortName &&
                            <div className="text-warning mt-2">{story.shortName}</div>
                        }
                    </div>

                    <div className="ml-3 cursor-pointer" onClick={() => downloadPdf(story)}>
                        <img src={pdfIcon} alt="download document"/>
                    </div>
                </div>
                {
                    (story.parents.length>0 || story.children.length>0 || story.associates.length>0) &&
                    <div className="d-flex flex-column border border-primary rounded p-3 mr-3 mt-3 story-boxes">
                        <div className="font-bold">Relationships</div>
                        {
                            story.parents.map((parent, index)=>{
                                return(
                                    <div className="d-flex mt-2 text-warning align-items-center" key={index}>
                                        <img src={tickMarkImage} alt="parent" height={14}/>
                                        <span className="ml-1">Parent :
                                            <NavLink className="ml-1" to={`/story/${parent._id}`}>{parent.title}</NavLink>
                                        </span>
                                    </div>
                                )
                            })
                        }
                        {
                            story.children.map((child, index)=>{
                                return(
                                    <div className="d-flex mt-2 text-warning align-items-center" key={index}>
                                        <img src={tickMarkImage} alt="child" height={14}/>
                                        <span className="ml-1">Child :
                                            <NavLink className="ml-1" to={`/story/${child._id}`}>{child.title}</NavLink>
                                        </span>
                                    </div>
                                )
                            })
                        }
                        {
                            story.associates.map((associate, index)=>{
                                return(
                                    <div className="d-flex mt-2 text-warning align-items-center" key={index}>
                                        <img src={tickMarkImage} alt="associate" height={14}/>
                                        <span className="ml-1">Associate :
                                            <NavLink className="ml-1" to={`/story/${associate._id}`}>{associate.title}</NavLink>
                                        </span>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                {
                    (story.people && story.people.length > 0) &&
                    <div className="d-flex flex-column border border-primary rounded p-3 mr-3 mt-3 story-boxes">
                        <div className="font-bold">People Involved</div>
                        {
                            story.people.map((people, index) => {
                                return (
                                    <div className="d-flex mt-2 text-warning align-items-center" key={index}>
                                        {people.name}
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                {
                    Object.keys(story).filter(key => !storyExcludedFields.includes(key) && !_.isEmpty(story[key])).map((key, index) => {
                        return (
                            <div className="d-flex flex-column border border-primary rounded p-3 mr-3 mt-3 story-boxes"
                                 key={index}>
                                <div className="font-bold">{storyView[key] || key}</div>
                                {
                                    Array.isArray(story[key]) &&
                                    story[key].map((value, index) => {
                                        return (
                                            <div className="d-flex mt-2 text-warning align-items-center" key={index}>
                                                {value}
                                            </div>
                                        )
                                    })
                                }
                                {
                                    !Array.isArray(story[key]) &&
                                    story[key]
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}
